<template>
  <v-card class="pa-8">
    <v-row>
      <div class="d-flex mb-3">
        <data-loader :custom-loading-trigger="isLoading" class="mr-4" />
        <h3 v-if="!isLoading">
          {{ $t('global.status.title') }}
        </h3>
        <h3 v-else>{{ $t('global.loading-data') }}</h3>
      </div>

    </v-row>

    <v-row v-show="!isLoading" align="center" class="mb-4">
      <v-col>
        <div
          class="px-4 py-1 background rounded text-center font-weight-medium"
        >
          {{ label(status) }}
        </div>
      </v-col>
    </v-row>

    <v-row v-show="!isLoading">
      <v-col class="text--secondary text-center">
        {{ statusDescription }}
      </v-col>
    </v-row>

    <!-- Reservation status (only when MATCHING) -->
    <v-row v-show="!isLoading && status === `MATCHING`" class="my-1 align-center justify-center">
      <v-col class="pa-0 flex-grow-0" style="width: 50px">
        <v-icon v-if="reservedMatchId" class="pr-2 py-0" align-self="center">mdi-lock</v-icon>
        <v-icon v-else class="pr-2 py-0" align-self="center">mdi-lock-open</v-icon>
      </v-col>
      <v-col v-if="reservedMatchId" class="pl-1 text--secondary text-left flex-grow-0 nowrap">
        <a @click="$router.push(`/matching/${reservedMatchId}`)">{{ $t("global.see_reservation") }}</a>
      </v-col>
      <v-col v-else class="pl-1 text--secondary text-left flex-grow-0 nowrap">
        {{ $t("global.available") }}
      </v-col>
    </v-row>

    <!-- Also display warning for invalid MATCHING content created before the validation implementation -->
    <v-row v-show="((next === `MATCHING` || status === `MATCHING`) && !isSelectionValid)">
      <v-col class="text-center warning--text" v-html="$t('global.status.warning')" />
    </v-row>

    <v-row v-show="error">
      <v-col class="text-center error--text">
        {{ error }}
      </v-col>
    </v-row>

    <v-row
      v-show="!isLoading"
      class="d-print-none"
    >
      <!-- Previous status if available -->
      <v-col
        v-if="previous"
        align-self="center"
        class="text-center flex-grow-1"
        cols="6"
        style="max-width: 100%;"
      >
        <v-btn small block color="default" class="primary--text text--darken-2" @click.stop="askFor(previous)">
          <v-icon left>mdi-arrow-left-bold-circle</v-icon>
          {{ label(previous) }}
        </v-btn>
      </v-col>

      <!-- Next status if available -->
      <v-col
        v-if="next"
        align-self="center"
        class="text-center flex-grow-1"
        cols="6"
        style="max-width: 100%;"
      >
        <v-btn :disabled="(next === `MATCHING` && !isSelectionValid)" small block color="default" class="primary--text text--darken-2" @click.stop="askFor(next)">
          {{ label(next) }}
          <v-icon right>mdi-arrow-right-bold-circle</v-icon>
        </v-btn>
      </v-col>

      <!-- Archive status if not already -->
      <v-col
        v-if="archive"
        align-self="center"
        class="text-center flex-grow-1"
        cols="6"
        style="max-width: 100%;"
      >
        <v-btn small block color="default" class="primary--text text--darken-2" @click.stop="askFor(archive)">
          {{ label(archive) }}
          <v-icon right>mdi-archive</v-icon>
        </v-btn>
      </v-col>

      <v-dialog v-model="dialog" max-width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            {{ $t("global.status.confirm") }}
          </v-card-title>
          <v-card-text class="pt-5 pb-1" v-html="dialogText" />
          <v-card-actions class="pb-6 pr-6">
            <v-spacer></v-spacer>
            <v-btn color="default" @click="dialog = false">
              {{ $t("global.status.cancel") }}
            </v-btn>
            <v-btn color="primary" @click="goTo(dialogTarget)">
              {{ $t("global.status.ok") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-row>
  </v-card>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import DataLoader from "@/components/common/DataLoader.vue";


const statuses = Object.freeze([
  "NEW",
  "READY",
  "MATCHING",
  "ARCHIVED",
])

export default {
  components: {DataLoader},
  props: {
    entityType: {
      type: String,
      required: false,
      default: ""
    },
    entity: {
      type: Object,
      required: false,
      default: () => null
    },
    isSelectionValid: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      dialog: false,
      dialogText: "",
      dialogTarget: "",
      isLoading: false,
      error: false,
    }
  },
  computed: {
    ...mapGetters('page', ['dataIsLoading']),
    statuses() {
      return statuses
    },
    status() {
      return (this.entity && this.entity.status) ? this.entity.status[0] : this.statuses[0]
    },
    entityLabel() {
      return this.$t('global.status')[this.entityType]
    },
    statusDescription() {
      const status = this.$t('global.status')[this.status]
      if (status) {
        const text = status.description.replace("{entityType}", this.entityLabel)
        return text.charAt(0).toUpperCase() + text.slice(1)
      }
      return ""
    },
    previous() {
      return this.statuses[this.statuses.indexOf(this.status) - ((this.status === "ARCHIVED") ? 2 : 1)]
    },
    next() {
      // Next status (except ARCHIVED, which is always available)
      const nextIndex = this.statuses.indexOf(this.status) + 1
      return (this.statuses[nextIndex] === "ARCHIVED") ? false : this.statuses[nextIndex]
    },
    archive() {
      return (this.status === "ARCHIVED") ? false : "ARCHIVED"
    },
    reservedMatchId() {
      return (this.status === "MATCHING" && this.entity?.reservedMatchId)
    }
  },
  methods: {
    ...mapActions('jobApplications', ['updateJobApplicationStatus']),
    ...mapActions('jobOffers', ['updateJobOfferStatus']),
    label(status) {
      if (status !== "") {
        return this.$t('global.status')[status].label
      }
      return ""
    },
    askFor(status) {
      if (status === "MATCHING") {
        // Ask confirmation & Compute matches (new matches)
        this.dialogText = this.$t('global.status.MATCHING.confirm', {'entityType': this.entityLabel})
        this.dialogTarget = status
        this.dialog = true
      } else if (this.status === "MATCHING") {
        // Ask confirmation & Compute matches (cleanup matches)
        this.dialogText = this.$t(`global.status.${status}.confirm.${this.entityType}`)
        this.dialogTarget = status
        this.dialog = true
      } else {
        // Just change statuses
        this.goTo(status)
      }
    },
    goTo(status) {
      this.dialog = false
      this.isLoading = true
      if (this.entityType === 'application') {
        this.updateJobApplicationStatus(status).then(() => {
          this.isLoading = false
        }).catch((data) => {
          this.error = this.$t(`global.status.error.${data.code}`)
          this.isLoading = false
        })
      }
      if (this.entityType === 'offer') {
        this.updateJobOfferStatus(status).then(() => {
          this.isLoading = false
        }).catch(data => {
          this.error = this.$t(`global.status.error.${data.code}`)
          this.isLoading = false
        })
      }
    }
  }
}
</script>
